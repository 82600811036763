import React, { Component } from 'react'
import { Container, Row, Col, Alert } from 'react-bootstrap'

import BookJacket from '../../../content/images/generation-medemer-book-jacket.jpeg'

class GenerationMedemerBook extends Component {
  render() {
    return (
      <section style={{ paddingBottom: '0px' }}>
        <Container style={{ borderBottom: '1px solid #e1e1e1' }}>
          <Row>
            <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
              <div className="d-none d-md-block">
                <h2>‘Generation Medemer’ Now Available</h2>
              </div>
              <p>&nbsp;</p>
              <p>
                A nation is built or destroyed in the handover across
                generations.
              </p>
              <p>
                It will be built by a generation that will do more with what it
                was handed over from its predecessors. It will be destroyed by a
                generation that does less than it has taken over.
              </p>
              <p>
                To build a better country is possible by learning from the past
                generations, by making an effort with the current one, and by
                cultivating tomorrow's generation.
              </p>
              <p>The question is how?</p>
              <p>
                The book ‘Generation Medemer’ attempts to answer this very
                question.
              </p>
              <p>&nbsp;</p>
              <p>
                <a
                  className="btn btn-primary btn-medemer"
                  href="https://ethio.post/product/generation-medemer/"
                  role="button"
                  target="_blank"
                >
                  Buy the Book
                </a>
              </p>
              <p>&nbsp;</p>
            </Col>

            <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }}>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/6nl4i-eLfoE"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              {/* <img src={BookJacket} className="img-responsive" width="100%" /> */}
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

export default GenerationMedemerBook

import React, { Component } from 'react'
import { Container, Row, Col, Alert } from 'react-bootstrap'

import BookJacket from '../../../content/images/book-jacket.jpg'

class Book extends Component {
  render() {
    return (
      <section>
        <Container>
          <Row>
            {/* <Col xs={12} md={12}>
              <Alert variant="info" style={{ textAlign: 'center' }}>
                <h2>Gift to the nation in times of Covid19</h2>
                MEDEMER in audio book format will be released for free downloads
                and listening on Wednesday May 20th, 2020.
              </Alert>

              <p>&nbsp;</p>
            </Col> */}

            <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
              <div className="d-none d-md-block">
                <h2>
                  MEDEMER in audio book now available for free downloads and
                  listening. You can also listen to daily chapter readings on
                  Fana Radio and Ethiopia Radio.
                </h2>
              </div>
              <p>&nbsp;</p>
              <p>
                Medemer is the first book by Nobel Peace Prize awardee and Prime
                Minister of Ethiopia, Abiy Ahmed since he came to power in 2018.
                In the book, the prime minister advocates for a fresh,
                Ethiopian-centric approach to the country's politics, citing the
                past half-century when previous administrations applied
                successful ideologies and theories from outside of Ethiopia that
                failed, being alien to Ethiopian problems and realities. Abiy
                calls for reversing the trend of importing ideologies for a
                renewed Ethiopian political ideology that emanates from
                Ethiopia's social-political context and taps into the country's
                historical and cultural values.
              </p>
              <p>&nbsp;</p>
              <p>
                <a
                  className="btn btn-primary btn-medemer"
                  href="https://amzn.to/2OfugpM"
                  role="button"
                >
                  Order Amharic Book
                </a>
                &nbsp;
                <a
                  className="btn btn-primary btn-medemer"
                  href="https://amzn.to/2qUKa0V"
                  role="button"
                >
                  Order Afaan Oromo Book
                </a>
              </p>
              <p>&nbsp;</p>
              <img src={BookJacket} className="img-responsive" width="100%" />
            </Col>
            <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }}>
              <div className="d-sm-block d-lg-none d-md-none">
                <h2>
                  MEDEMER in audio book now available for free downloads and
                  listening. You can also listen to daily chapter readings on
                  Fana Radio and Ethiopia Radio.
                </h2>
                <p>&nbsp;</p>
              </div>
              <iframe
                width="100%"
                height="450"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1098592537%3Fsecret_token%3Ds-M2x19xefk0m&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>

              <p>&nbsp;</p>

              <iframe
                width="100%"
                height="450"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1054526542&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

export default Book

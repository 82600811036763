import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Accordion, Card } from 'react-bootstrap'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'
import Icon from 'components/icon'
import BookComponent from 'components/book'
import GenerationMedemerBook from 'components/generation-medemer-book'
import Medemer from 'components/medemer'

import PMPortrait from '../../content/images/pm-abiy-3.jpg'

class Book extends React.Component {
  render() {
    const { location, data } = this.props

    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="Book" />

        <div>
          <section className="jumbotron text-left">
            <div className="container">
              <h1 className="jumbotron-heading">Book</h1>
              <p className="lead text-muted">
                We're excited to announce that our highly anticipated book
                'Generation Medemer’ is almost here! To make sure you're one of
                the first to know when it's available for online sales, sign up
                for our mailing list. By doing so, you'll receive all the latest
                news and updates about the book, including release dates.
              </p>
            </div>
          </section>

          <GenerationMedemerBook />

          <BookComponent />

          <section style={{ paddingTop: '0px' }}>
            <div className="container marketing">
              <div className="row featurette">
                <div className="col-md-12 text-center">
                  <blockquote
                    style={{
                      fontSize: '2.2em',
                      fontWeight: 'bold',
                      marginBottom: '2em',
                    }}
                  >
                    "የመጀመሪያው እና ትልቁ ልማት ሰላም ነው።"
                  </blockquote>
                  <blockquote
                    style={{
                      fontSize: '2.2em',
                      fontWeight: 'bold',
                      marginBottom: '2em',
                    }}
                  >
                    "ዘረኝነት እና መከፋፈልን ከሀገራችን እናጥፋ፤ የተማረና በምክንያት የሚከራከር ዜጋ እንፍጠር።"
                  </blockquote>
                  <blockquote
                    style={{
                      fontSize: '2.2em',
                      fontWeight: 'bold',
                      marginBottom: '2em',
                    }}
                  >
                    "ለሴቶች ክብር የማይሰጥ ማህበረሰብ ያልሠለጠነ ብቻ ሳይሆን ወደፊትም የማይሠለጥን ነው።"
                  </blockquote>
                  <blockquote
                    style={{
                      fontSize: '2.2em',
                      fontWeight: 'bold',
                      marginBottom: '2em',
                    }}
                  >
                    "ጥበብ በባሕሪዋ የሚፈጥራትን ብቻ ሳይሆን ዐውቆ የሚጠቀምባትን ሕዝብ ትፈልጋለች።"
                  </blockquote>
                  <blockquote
                    style={{
                      fontSize: '2.2em',
                      fontWeight: 'bold',
                      marginBottom: '2em',
                    }}
                  >
                    "ሞትን ማሸነፍ አይቻልም፤ በሕይወት ውስጥ ማሸነፍ የሚቻለው [ግን] ጥላቻን ብቻ ነው።"
                  </blockquote>
                  <blockquote
                    style={{
                      fontSize: '2.2em',
                      fontWeight: 'bold',
                      marginBottom: '2em',
                    }}
                  >
                    "ድንበር ከጎረቤት ሀገራት ጋር እንጅ በአንድ ሀገር ውስጥ ሊኖር አይችልም።"
                  </blockquote>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Medemer />
      </Layout>
    )
  }
}

export default Book

export const query = graphql`
  query BookPageQuery {
    profile: file(name: { eq: "profile" }) {
      childImageSharp {
        fixed(width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    work1: file(name: { eq: "work1" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    work2: file(name: { eq: "work2" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    work3: file(name: { eq: "work3" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    back1: file(name: { eq: "back1" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    back2: file(name: { eq: "back2" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
  }
`
